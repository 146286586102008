
* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  
}

html {
  font-size: 80%;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #444;
  background-color: #f3f3f3;
  height: 100vh;
  padding: 1rem;
  font-size: 2rem;
}
.login__input {
  border: none;
  padding: 0.5rem 2rem;
  font-size: 1.6rem;
  font-family: inherit;
  text-align: center;
  width: 12rem;
  border-radius: 10rem;
  margin-right: 1rem;
  color: inherit;
  border: 1px solid #fff;
  transition: all 0.3s;
}